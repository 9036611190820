function OpenStreetMap() {
  this.init = function() {
    Array.from(document.querySelectorAll('.obis-site-openstreetmap')).forEach(function (mapContainer) {
      var mapId = mapContainer.getAttribute('id');
      initMap(mapContainer, mapId);
    });
  }
  
  initMap = function(mapContainer, mapId) {
      var map = L.map(mapId);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      var myicon = L.icon({
        iconUrl: '/_Resources/Static/Packages/Obis.Site/Images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [-10, -85],
        shadowUrl: '/_Resources/Static/Packages/Obis.Site/Images/marker-shadow.png',
        shadowSize: [41, 41],
        shadowAnchor: [10, 41]
      });

      var markers = JSON.parse(mapContainer.getAttribute('data-marker'));
      var latlngs = [];

      $.each(markers.markers, function (index, item) {
        var buff = markers.markers[index]['m' + index][0];
        addMarker(buff.lat, buff.lon, buff.text, map, myicon); 
        latlngs.push([buff.lat, buff.lon]);
      }); 

      map.fitBounds(latlngs,{padding:[100,100]});
      //map.setZoom(13);
  }

  addMarker = function(lat,lon,text,map,myicon) {
    L.marker([lat, lon],{icon: myicon}).addTo(map)
      .bindPopup(text);
  }
};

$(document).ready(function(){
  var cookiepopup = document.querySelector('div.pkcp').cookiepopup;
  if (cookiepopup.status.getCurrentStatus('functional') == cookiepopup.status.allow) {
    window.openStreetMap = new OpenStreetMap();
    window.openStreetMap.init();
  }
});
